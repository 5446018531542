<template>
  <div class="main_profile_wrapper">
    <div class="main_info_wrapper mb-5">
      <div class="info">
        <img
          class="img"
          src="../../../assets/new-theme/profile/Image wrap.png"
          alt=""
        />
      </div>
      <div class="info_data">
        <div class="wrap_one">
          <div class="main_user_img_wrapper">
            <img class="user_img" :src="image" alt="user image" />
          </div>
          <div>
            <span class="name d-block"
              >{{ userData.first_name }} {{ userData.last_name }}</span
            >
            <span class="email d-block">{{ userData.email }}</span>
          </div>
        </div>
        <div class="buttons_links">
          <button class="btn_1">
            <img
              class="img"
              src="../../../assets/new-theme/profile/edit-05.png"
              alt=""
            />
            <span>Edit Your Profile</span>
          </button>
          <button class="btn_2">
            <img
              class="img"
              src="../../../assets/new-theme/profile/share-07.png"
              alt=""
            />
            <span>Share</span>
          </button>
        </div>
      </div>
    </div>

    <!-- Start Patterns -->
    <UserPattern :userScore="userScore" :visible="true"></UserPattern>
    <!-- End Patterns -->

    <!-- Start Your Learner Type -->
    <div class="learner_wrapper">
      <h2 class="main_title ">Your Learner Type</h2>
      <div class="main_learn_card mt-3">
        <div class="mb-3">
          <img
            class="img"
            src="../../../assets/new-theme/profile/Featured icon.png"
            alt=""
          />
        </div>
        <div>
          <span class="pattern_name">Strong-Willed Learner</span>
        </div>
        <p class="mb-0 text">
          The Strong-Willed Learner uses three or more Patterns at the Use First
          level.
        </p>
        <div class="mt-3 mb-3">
          <span class="d-block Characteristics mb-3">Characteristics</span>
          <ul class="list_items">
            <li class="item_in_list mt-1">
              Prefers to lead rather than be led.
            </li>
            <li class="item_in_list mt-1">
              Prefers to control plans, ideas, talks, decisions, processes, and
              outcomes independently.
            </li>
          </ul>
        </div>
        <p class="mb-0 text">
          It's crucial to understand how your Patterns impact those around you.
        </p>
        <div class="mt-3 learn_more">
          <span class="color more">Learn more</span>
          <img
            class="img"
            src="../../../assets/new-theme/profile/arrow-right.png"
            alt=""
          />
        </div>
      </div>
    </div>
    <!-- End Your Learner Type -->

    <!-- Use Your Learning Patterns -->
    <div class="learner_patterns_wrapper">
      <h2 class="main_title ">Use Your Learning Patterns</h2>
      <p class="mb-0 text">
        Add your own task and discover what patterns are needed to successfully
        complete it.
      </p>
      <div class="main_cards mt-3">
        <div class="card_item">
          <div class="mb-3">
            <img
              class="img"
              src="../../../assets/new-theme/profile/book.png"
              alt=""
            />
          </div>
          <div class="mb-4">
            <span class="title mb-2 d-block">
              Study-related task
            </span>
            <p class="mb-0 description">
              Add new tasks in math, reading, writing, study skills, science,
              social studies, etc...
            </p>
          </div>
          <div>
            <button class="lci_btn ">
              <img
                class="img"
                src="../../../assets/new-theme/profile/plus.png"
                alt=""
              />
              <span>Add a new study-related task</span>
            </button>
          </div>
        </div>
        <div class="card_item">
          <div class="mb-3">
            <img
              class="img"
              src="../../../assets/new-theme/profile/wallet.png"
              alt=""
            />
          </div>
          <div class="mb-4">
            <span class="title mb-2 d-block">
              Work-related task
            </span>
            <p class="mb-0 description">
              Add new tasks in time management, team interaction, meeting
              preparation, etc...
            </p>
          </div>
          <div>
            <button class="lci_btn ">
              <img
                class="img"
                src="../../../assets/new-theme/profile/plus.png"
                alt=""
              />
              <span>Add a new work-related task</span>
            </button>
          </div>
        </div>
      </div>
    </div>
    <!-- Use Your Learning Patterns -->

    <!-- Use Your Personal Patterns -->
    <div class="your_personal_wrapper">
      <h2 class="main_title ">Build Your Personal Work Profile (PWP)</h2>
      <p class="mb-0 text">
        And see if the job is the right fit for you!
      </p>
      <div class="mt-3">
        <button class="lci_btn ">
          <span>Get strategies!</span>
          <img
            class="img"
            src="../../../assets/new-theme/profile/arrow-right-white.png"
            alt=""
          />
        </button>
      </div>
    </div>

    <!-- Personalize Your Profile-->
    <div class="personalize_profile">
      <div>
        <div class="item_wrap">
          <h2 class="main_title white_text mb-0 mt-0">
            Build Your Personal Work Profile (PWP)
          </h2>
          <span class="recommended">Recommended!</span>
        </div>
        <p class="mb-0 text white_text mt-2">
          And share with others how you learn, work, and interact with others.
        </p>
      </div>
      <div class="last_section">
        <img
          class="img"
          src="../../../assets/new-theme/profile/arrow-right.png"
          alt=""
        />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ProfileView",
  data() {
    return {};
  },
  computed: {
    mainUserId() {
      return this.$route.params.id ? this.$route.params.id : this.auth().id;
    },
    userScore() {
      return this.$store.getters["user/score"];
    },
    userData() {
      return this.$store.state.user.user;
    },
    image() {
      if (this.userData.image) {
        return `${process.env.VUE_APP_BASE_URL}/users/photo/${this.userData.image}`;
      }
      return "/static/img/lml-swerl.jpg";
    }
  },
  methods: {
    // Get User Data And Set User Score
    getMainUserData() {
      this.$store.dispatch("user/user", this.mainUserId).then(() => {
        // Set the Selected User attribute
        this.selected = this.userData.selected_attributes;
      });
    },
    getProgressColor(key) {
      switch (key) {
        case "Precision":
          return "#3c3";
        case "Technical Reasoning":
          return "#f93";
        case "Confluence":
          return "#c36";
        case "Sequence":
          return "#39c";
      }
    }
  },
  mounted() {
    this.getMainUserData();
  }
};
</script>

<style lang="scss" scoped>
.main_profile_wrapper {
  background: #fff;
  width: 100%;
  max-width: 100%;
  padding: 0 30px 50px;

  .main_title {
    color: #101828;
    font-weight: 600;
    font-size: 20px;
    &.white_text {
      color: #fff;
    }
  }

  // Start Your Learner Typ
  .learner_wrapper {
    margin-top: 40px;
    .main_learn_card {
      box-shadow: 0px 4px 6px -2px #10182808, 0px 12px 16px -4px #10182814;
      border-radius: 8px;
      border: 1px solid #eaecf0;
      padding: 24px;
      .pattern_name {
        font-weight: 500;
        font-size: 18px;
        color: #101828;
        margin-bottom: 10px;
        display: block;
      }
      .text {
        color: #667085;
        font-weight: 400;
        font-size: 16px;
      }
      .Characteristics {
        font-weight: 400;
        font-size: 16px;
        color: #667085;
      }
      .list_items {
        margin-bottom: 0 !important;
        margin-left: 0;
      }
      .item_in_list {
        font-weight: 400;
        font-size: 15px;
        color: #667085;
      }
    }
    .learn_more {
      display: flex;
      align-items: center;
      gap: 10px;
    }
    .more {
      font-weight: 500;
      font-size: 16px;
    }
  }
  // Use Your Learning Patterns
  .learner_patterns_wrapper {
    margin-top: 40px;
    .text {
      color: #667085;
      font-weight: 400;
      font-size: 16px;
    }
    .main_cards {
      display: flex;
      justify-content: space-between;
      gap: 4%;
      .card_item {
        border-radius: 8px;
        border: 1px solid #eaecf0;
        padding: 24px;
        width: calc(96% / 2);

        .title {
          color: #101828;
          font-weight: 600;
          font-size: 18px;
        }
        .description {
          color: #667085;
          font-weight: 400;
          font-size: 16px;
        }
        .lci_btn {
          background-color: #f2f4f7;
          color: #6941c6;
          border-radius: 8px;
          padding: 12px 20px;
          border: 1px solid #eaecf0;
          display: flex;
          align-items: center;
          justify-content: center;
          gap: 8px;
          width: 100%;
          font-weight: 500;
          font-size: 16px;
        }
      }
    }
  }

  .your_personal_wrapper {
    margin-top: 40px;
    border-radius: 8px;
    border: 1px solid #eaecf0;
    padding: 24px;
    .text {
      color: #667085;
      font-weight: 400;
      font-size: 16px;
    }
    .lci_btn {
      border-radius: 8px;
      padding: 12px 20px;
      border: 1px solid #eaecf0;
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 8px;
      width: 240px;
      font-weight: 500;
      font-size: 16px;
      background-color: #7f56d9;
      color: #fff;
      &.save {
      }
    }
  }
  .personalize_profile {
    margin-top: 40px;
    border-radius: 8px;
    background-color: #7f56d9;
    color: #fff;
    padding: 15px 24px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .text {
      color: #fff;
      font-weight: 400;
      font-size: 16px;
    }
    .item_wrap {
      display: flex;
      gap: 20px;
      align-items: center;
    }
    .recommended {
      background-color: #fff;
      color: #7f56d9;
      font-size: 16px;
      font-weight: 500;

      padding: 2px 8px 2px 8px;
      border-radius: 16px;
    }
    .last_section {
      background-color: #fff;
      height: 32px;
      width: 32px;
      border-radius: 8px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
  .color {
    color: #6941c6;
  }
}
</style>
